/* eslint-disable max-len */
export const servicesOffered = [
//   {
//     title: "Conseil et stratégie",
//     description: `Confiez l'audit de votre Site Internet et l'élaboration de votre stratégie digitale à notre agence de communication digitale parisiennne :
// étude des besoins et recherche, analyse et cahier des charges, nom de domaine et hébergement, recommandations et plan de stratégie digitale.
// Conseil en communication, nous vous accompagnons également sur votre branding, votre positionnement de marque, votre identité et votre storytelling.
// Pour le choix de votre nom de domaine, sous-domaine, hébergeur, Ssl, https, nous vous conseillons et nous en occupons`,
//     image: "/strategy.jpg",
//   },
  {
    title: "Développement de solutions",
    subtitle: "Nous créons des outils performants adaptés",
    description: `Développez un site responsive design, et/ou une application mobile avec les meilleurs frameworks du marché, grâce à notre agence de développement de sites web et mobile.
Nous proposons les dernières technologies front-end (visuel) et back-end (gestion de contenu), avec une ergonomie optimale quel que soit le support.`,
    image: "/development.png",
  },
  {
    title: "Gestion de projet",
    subtitle: "Atteignez vos objectifs",
    description: `Notre entreprise spécialisée en développement de solutions innovantes propose un service complet de gestion de projet
          pour vous accompagner dans la réalisation de vos projets numériques.
          Que vous soyez une start-up, une PME ou une grande entreprise, notre équipe expérimentée est là pour vous aider à atteindre vos objectifs dans les délais et les budgets impartis.`,
    image: "/project_managment.png",
  },
  {
    title: "Rédaction de contenu web",
    subtitle: "Optimisez vos textes pour le SEO",
    description: `Confiez à notre agence de rédaction web l'optimisation de vos textes pour le SEO.
Des contenus originaux pour attirer plus de clients et bien référencer votre site internet sur les moteurs de recherche.`,
    image: "/copywriting.png",
  },
  {
    title: "Accompagnement",
    subtitle: "Donnez du sens à vos besoins",
    description: `Notre entreprise propose un service d'accompagnement personnalisé pour vous aider à mener à bien vos projets numériques.
          Pendant toute la durée de notre accompagnement, nous restons à votre disposition pour répondre à vos questions, vous fournir des conseils techniques,
          et vous aider à surmonter les éventuels obstacles rencontrés. Nous mettons à votre disposition notre expertise en matière de conduite du changement,
          en utilisant les meilleures pratiques et les technologies les plus récentes pour vous assurer des résultats de qualité.`,
    image: "/customer_service.png",
    shortDescription: `Notre entreprise propose un service d'accompagnement personnalisé pour vous aider à mener à bien vos projets numériques.
 Nous mettons à votre disposition notre expertise en matière de conduite du changement,
    en utilisant les meilleures pratiques et les technologies les plus récentes pour vous assurer des résultats de qualité.`,

  },
];

export const targets = [
  "porteurs de projets,",
  "TPE/PME,",
  "artisans,",
  "collectivités,",
  "associations,",
];

export const slogans = [
  "Parce qu'une idée sans solution adaptée, est juste une idée...",
  "Amazonie innovation intervient à chaque étape de votre projet, du croquis au cloud",
  "Nous parlons le même langage",
];

export const references = [
  {
    title: "Winamax",
    logo: "/winamax.png",
  },
  {
    title: "GDI",
    logo: "/gdi.png",
  },
  {
    title: "FRBTP",
    logo: "/FRBTP.png",
  }, {
    title: "CERC",
    logo: "/cerc.png",
  },
  {
    title: "G.A.D.V.F",
    logo: "/gadvf.jpeg",
    skills: [],
    url: "https://www.gadvf.fr",
  },
  {
    title: "xperts Council",
    logo: "/xpertsCouncil.webp",
    skills: ["React.js", "Angular.js"],
  },
  {
    title: "Ordissimo",
    logo: "/ordissimo.png",
    skills: ["Symfony", "Drupal"],
  },

  {
    title: "Yooi",
    logo: "/yooi.svg",
    skills: ["React.js", "Node.js", "AWS", "Redis"],
  },

];

export const legals = [
  "Ce site est la propriété exclusive de la société AMAZONIE INNOVATION, SASU au capital 1000,00 euros, immatriculée au RCS de Cayenne sous le numéro B 978 422 301",
  "Le siège social est situé au 17lot cotonnière EST, 756C rue de la cotonnière EST 97351 MATOURY (Tél : 0610 82 46 25)",
  "Mail: contact@amazonie-innovation.com",
  "L’hébergement de ce site est assuré par la société HOSTINGER INTERNATIONAL LTD, dont le siège social se situe 61 Lordou Vironos Street, 6023 Larnaca, Chypre, joignable par le moyen suivant : https://www.hostinger.fr/contact.",
  "Le site peut faire usage de cookies qui ont pour but d’améliorer le service des utilisateurs et seront éventuellement utilisés à cette seule fin.",

];
