import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { styled } from "styled-components";

const StyledNoMatch = styled.div`
  height: 100vh;

  padding-top: 10rem;
  img {
    margin: auto;
  }
`;
function NoMatch() {
  useEffect(() => {
    document.title = "404 Not found";
  }, []);

  return (
    <StyledNoMatch>
      <img src="/404.png" alt="404" width={250} />
      <h1>Aucune innovation ici pour le moment...</h1>
      <a href="/">
        <FontAwesomeIcon icon={faHouse} size="xl" />
      </a>
    </StyledNoMatch>
  );
}

export default NoMatch;
