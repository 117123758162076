import React from "react";
import { styled } from "styled-components";

const StyledButton = styled.button`
  background-color: rgba(30,166,76,1);
  border-radius: 25px;
  border: none;
  font: 110% system-ui;
  font: ${(props) => (props.$isMobile ? "80% " : "110%")}  system-ui;
  margin: auto;
  padding: 1rem;
  color: white;
  cursor: pointer;

  a {
    all: unset
  }
`;
function ContactButton({ buttonText, className, isMobile = false }) {
  return (
    <StyledButton $isMobile={isMobile} className={className}><a href="https://qbm713bgh0h.typeform.com/to/TaBCseR8" target="_blank" rel="noreferrer">{buttonText}</a></StyledButton>
  );
}

export default ContactButton;
