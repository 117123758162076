import React, { useContext, useEffect } from "react";
import { styled } from "styled-components";
import { useMediaQuery } from "react-responsive";
import AppContext from "../contexts/appContext";
import { legals } from "../../constants";
import deviceSize from "../responsive";

const StyledLegals = styled.div`
  margin-top: ${(props) => props.$navbarHeight}px;
  line-height: 2.3;
  text-align: left;
  height: calc(100vh - ${(props) => props.$navbarHeight}px);
  padding: 1rem;
  
  ${(props) => props.$isMobile && `
  height: 100%;
  `}

  ul {
    padding: 0
  }

  li {
    list-style-type: none;
    margin-bottom: 1rem;
  }
`;

function Legals() {
  const { navbarHeight, footerHeight } = useContext(AppContext);
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  useEffect(() => {
    document.title = "Mentions légales | AMAZONIE INNOVATION";
  }, []);

  return (
    <StyledLegals
      $navbarHeight={navbarHeight}
      $offset={navbarHeight + footerHeight}
      $isMobile={isMobile}
    >
      <h1>Mentions légales</h1>
      <ul>
        {legals.map((item) => <li key={item}>{item}</li>)}
      </ul>
    </StyledLegals>
  );
}

export default Legals;
