import React from "react";
import { styled } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import deviceSize from "../responsive";

const StyledFooter = styled.div`
  color: white;
  background-color:  rgba(11, 23, 39,1);
  display: flex;
  
  img {
    display: block;
    max-height: 7rem;
    bottom: 1rem;
    margin: auto;
  }

  div {
    width: 30%;
    text-align: center;
    margin: auto;
    padding: 1rem;
    font-size: ${(props) => (props.$isMobile ? "80%" : "100%")};
  }

  a {
    all: unset;
    cursor: pointer;
    margin: auto;
    width: 30%;
    text-align: center;
    padding: 1rem;
    &:hover{
      color: rgba(30, 166, 76, 1);
    }
  }
`;

function Footer(props, ref) {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  return (
    <StyledFooter ref={ref} $isMobile={isMobile}>
      {isMobile
        ? <div><a href="mailto:contact@amazonie-innovation.com">contact</a></div>
        : <div><a href="mailto:contact@amazonie-innovation.com">contact@amazonie-innovation.com</a></div>}
      {!isMobile && <img src="/logo.png" alt="logo" />}
      <Link to="/mentions-legales">Mentions légales</Link>

    </StyledFooter>
  );
}

export default React.forwardRef(Footer);
