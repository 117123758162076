import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from "react";
import { css, styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import TypewriterComponent from "typewriter-effect";
import { useMediaQuery } from "react-responsive";
import {
  references, servicesOffered, slogans, targets,
} from "../../constants";
import useIsVisible from "../../hooks/useIsVisible";
import AppContext from "../contexts/appContext";
import deviceSize from "../responsive";
import MobileServices from "../template/MobileServices";
import ContactButton from "../ContactButton";
import {
  blink, growHorizontally, growVertically, slideBottomIn, slideBottomUp,
} from "../../keyframes";

//  put h2 back to uppercase

const StyledHome = styled.div`
  color: white;

  .illustration {
    display: ${(props) => (props.$isPortable ? "none" : "visible")};
  }

  h1, h2 {
    text-transform: uppercase;
  }
`;

const StyledHero = styled.div`
  background-image: url("/background.png");
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;

  ${(props) => props.$isMobile && `
    @media only screen and (orientation: portrait){
      background-size: auto 100%;
    }
  `}
`;

const StyledMainTitle = styled.h1`
  text-shadow: 0px 3px 0px #b2a98f,
                 0px 14px 10px rgba(0,0,0,0.15),
                 0px 24px 2px rgba(0,0,0,0.1),
                 0px 34px 30px rgba(0,0,0,0.1);
  text-transform: uppercase;
  font-size: 8vmin;
  margin: auto;
  opacity: 0;
  animation: ${slideBottomIn} 1.4s cubic-bezier(.18,.89,.32,1.28) 0.2s 1 forwards;
  .separator {
    font-weight: 300;
  }
`;

const IntroDescription = styled.div`
  width: 90%;
  text-align: left;
  margin: 0 auto auto auto;
  opacity: 0;

  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: ${slideBottomUp} 0.6s ease-out 1.4s 1 forwards;

  span {
    color: gold;
  }

  ${(props) => props.$isMobile && `
    margin-bottom: 2rem;
  `}
`;

const StyledCTA = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 3rem;
`;

const DownButton = styled.div`
  border: 1px solid white;
  border-radius: 20%;
  margin: auto;
  padding: 0.6rem;
  cursor: pointer;
  svg {
    animation: ${blink} 1s ease 0s infinite alternate ;
  }
`;

const StyledSlogan = styled.div`
  color: black;
  display: flex;
  padding: 2rem;
  scroll-margin-top: ${(props) => `${props.$navbarHeight}px`};
`;

const StyledSloganText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
`;

const StyledSloganImageMobile = styled.div`
  margin-left: auto;
  margin-right: auto;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const StyledServices = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  scroll-margin-top: ${(props) => `${props.$navbarHeight}px`};

  `;

const ServiceTitles = styled.div`
  display: flex;
  white-space: nowrap;
  font-size: 2.1vmin;
  margin-left: 2rem;
  ul { 
    margin-left: auto;
    margin-right: auto;
    list-style-type: none;
    display: flex;

    flex-direction: column;
    text-transform: uppercase;
    text-align: left;
    gap: 4rem;
  }

  li {
    button {
      &:hover {
        color: rgba(30,166,76,1);
        border-bottom: 1px solid rgba(30,166,76,1);
        transform: scale(1.01);
      }
    }
  }

  .active {
        color: rgba(30,166,76,1);
        transform: scale(1.01);
  }   

  button {
    all: unset;
    cursor: pointer;
  }
`;

const ServicesList = styled.div`
  display: flex;
`;

const ServiceDescription = styled.p`
  display: flex;
  text-align: left;
  font-size: 2vmin;
  padding: 0 1rem 0 0;

`;

const FirstLetter = styled.span`
  display: contents;
  font-weight: bold;
  color: rgba(30,166,76,1);
  font-size: 1.5rem;
`;

const ServiceDefinition = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 2vmin;
  padding: 0 3rem 0 3rem;
  line-height: 2;
`;

const ServiceSubtitle = styled.h3`
  /* font-size: 1.9vmin; */
  font-weight: bold;
  color: rgba(30,166,76,1);
`;

const ServiceImage = styled.div`
  margin: auto;
`;

const StyledReferences = styled.div`
  background-image: url("/background-s2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem 1rem 3rem;
  scroll-margin-top: ${(props) => `${props.$navbarHeight}px`};

  ul { 
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    ${(props) => props.$isPortable && `
        grid-template-columns: repeat(2, 1fr);
        img {
          width: 7rem;
        }
    `}

    text-transform: uppercase;
    flex-direction: ${(props) => (props.$isPortable ? "column" : "row")};
    padding-left: 0;
    li {
      margin: auto;
      padding-bottom: 2rem;
    }
  }  
`;

const StyledHorizontalLine = styled.div`
  color: rgba(30,166,76,1);
  content: ' ';
  border: 1px solid;
  margin: auto;
  margin: 0 3rem 0 3rem;
  animation: ${(props) => props.$animate && css`${growHorizontally} 3.3s ease-out 0s 1;`};
  animation: name duration timing-function delay iteration-count direction fill-mode;
`;

const StyledVerticalLine = styled.div`
  color: rgba(30,166,76,1);
  content: ' ';
  border: 1px solid;
  margin: auto;
  margin: 0 3rem 0 3rem;
  animation: ${(props) => props.$animate && css`${growVertically} 2s ease-out 0s 1;`};
`;

const TypewriterIntro = styled.span`
  max-width: 30%;
  color: gold;
  text-transform: uppercase;
`;

const Typewriter = styled.div`
  font-size: ${(props) => (!props.$isLaptop ? "2rem" : "100%")};
  line-break: auto;
  word-wrap: break-word;
  max-width: 70%;
  height: 5rem;
  margin: auto;
`;

function Home({ servicesRef, referencesRef }) {
  const sloganSectionRef = useRef(null);
  const isSloganVisible = useIsVisible(sloganSectionRef);

  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });
  const isLaptop = useMediaQuery({ maxWidth: deviceSize.laptop });

  const isPortable = isTablet || isMobile;
  const { navbarHeight } = useContext(AppContext);

  const [currentService, setCurrentService] = useState(0);
  const [animateSlogan, setAnimateSlogan] = useState(false);
  useEffect(() => {
    if (isSloganVisible) {
      setAnimateSlogan(true);
    }
  }, [isSloganVisible]);

  const executeScroll = () => {
    if (sloganSectionRef && sloganSectionRef.current /* + other conditions */) {
      sloganSectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const onClickService = useCallback((index) => setCurrentService(index), []);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "AMAZONIE INNOVATION | Solutions digitales sur mesure";
  }, [window.location.pathname]);

  return (
    <StyledHome $isPortable={isPortable}>
      <StyledHero $isMobile={isMobile}>
        <StyledMainTitle>
          Agence web
          {isMobile ? <br /> : " "}
          en guyane
        </StyledMainTitle>
        <IntroDescription $isMobile={isMobile}>
          <TypewriterIntro>
            <TypewriterComponent
              options={{
                strings: targets,
                autoStart: true,
                loop: true,
                delay: 50,
              }}
            />
          </TypewriterIntro>

          Nous vous accompagnons dans la conception et la réalisation de votre projet numérique.
          <br />
          Développez votre
          {" "}
          <b>visibilité</b>
          {" "}
          avec un site web optimisé pour le SEO (référencement naturel).
          <br />
          {" "}
          <b>Élargissez</b>
          {" "}
          votre audience grâce à
          {" "}
          <span>AMAZONIE INNOVATION</span>
          .
        </IntroDescription>
        <StyledCTA>
          <ContactButton buttonText={"J'ai un projet"} />
          <DownButton onClick={executeScroll}><FontAwesomeIcon icon={faAnglesDown} style={{ color: "white" }} /></DownButton>
        </StyledCTA>
      </StyledHero>
      <StyledSlogan $navbarHeight={navbarHeight} ref={sloganSectionRef}>
        <StyledSloganText>
          <h2>Une solution tech pour chaque idée innovante</h2>

          {isPortable && (
            <StyledSloganImageMobile>
              <img src="/tech-solution.png" alt="tech-solution" />
            </StyledSloganImageMobile>
          ) }
          <Typewriter $isLaptop={isLaptop}>
            <TypewriterComponent
              options={{
                strings: slogans,
                autoStart: true,
                loop: true,
                delay: 50,
              }}
            />
          </Typewriter>

          <ContactButton buttonText={"J'ai un projet"} />
        </StyledSloganText>
        {!isPortable && <StyledVerticalLine $animate={animateSlogan} />}
        <img className="illustration" src="/tech-solution.png" alt="webdev" width={600} />
      </StyledSlogan>
      <StyledHorizontalLine $animate={animateSlogan} />
      <StyledServices ref={servicesRef} $navbarHeight={navbarHeight}>
        <h2>Les services que nous proposons</h2>
        {(isLaptop) && <MobileServices />}
        {!(isLaptop)
        && (
        <ServicesList>
          <ServiceTitles>
            <ul>
              {servicesOffered.map((service, index) => (
                <li key={service.title}>
                  <button className={currentService === index ? "active" : null} type="button" onClick={() => onClickService(index)}>{service.title}</button>
                </li>
              ))}
            </ul>
          </ServiceTitles>
          <ServiceDefinition>
            <ServiceSubtitle>
              {servicesOffered[currentService].subtitle}
            </ServiceSubtitle>
            <ServiceDescription>
              <FirstLetter>{servicesOffered[currentService].description.charAt(0)}</FirstLetter>
              {servicesOffered[currentService].description.substring(1)}
            </ServiceDescription>
          </ServiceDefinition>

          <ServiceImage className="illustration">
            <img
              src={servicesOffered[currentService].image}
              alt={servicesOffered[currentService].title}
              height={500}
            />
          </ServiceImage>

        </ServicesList>
        )}
      </StyledServices>
      <StyledReferences $isPortable={isPortable} ref={referencesRef} $navbarHeight={navbarHeight}>
        <h2>Ils nous ont fait confiance</h2>
        <ul>
          {references.map((reference) => (
            <li key={reference.title}>
              {reference?.url && <a href={reference.url} target="_blank" rel="noreferrer"><img src={reference.logo} alt={reference.title} width={150} /></a>}
              {!reference?.url && <img src={reference.logo} alt={reference.title} width={200} />}
            </li>
          ))}
        </ul>
        <h3>À votre tour</h3>
        <ContactButton buttonText={"J'ai un projet"} />
      </StyledReferences>
    </StyledHome>
  );
}

export default Home;
