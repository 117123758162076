import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { styled } from "styled-components";
import { servicesOffered } from "../../constants";

const StyledMobileServices = styled.div`
  display: flex;
  line-height: 1.5;

  button {
    all: unset;
    cursor: pointer;
    margin: auto;
    position: relative;

    &:disabled { 
      color: grey;
    }
  }

  h2 {
    text-decoration: underline;
    color:  rgba(30,166,76,1);
  }

  h3 { 
    text-align: left;
    padding-left: 1.5rem;
  }
`;

const ServiceDescription = styled.div`
  display: flex;
  text-align: left;
  padding: 1rem 1.5rem 0 1.5rem;
`;

const ServiceImage = styled.div`
  /* font-weight: bold; */
  margin: auto;
  
  img {
    max-width: 90%;
    height: auto;
    margin-top: 3rem
  }
`;

// fixme make component
const FirstLetter = styled.span`
  display: contents;
  font-weight: bold;
  color: rgba(30,166,76,1);
  font-size: 1.5rem;
`;

function MobileServices() {
  const [currentService, setCurrentService] = useState(0);

  const OnpreviousService = useCallback(
    () => setCurrentService((prev) => (prev > 0 ? (prev - 1) : 0)),
    [],
  );
  const OnNextService = useCallback(
    () => setCurrentService((prev) => (prev < servicesOffered.length - 1 ? (prev + 1) : prev)),
    [],
  );

  return (
    <StyledMobileServices>
      <button type="button" onClick={OnpreviousService} disabled={currentService === 0}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <div>

        <h2>{servicesOffered[currentService].title}</h2>
        <h3>{servicesOffered[currentService].subtitle}</h3>
        <ServiceDescription>
          <FirstLetter>
            {servicesOffered[currentService].shortDescription?.charAt(0)
            || servicesOffered[currentService].description.charAt(0)}
          </FirstLetter>
          {servicesOffered[currentService].shortDescription?.substring(1)
           || servicesOffered[currentService].description.substring(1)}
        </ServiceDescription>
        <ServiceImage>
          <img
            src={servicesOffered[currentService].image}
            alt={servicesOffered[currentService].title}
          />
        </ServiceImage>
      </div>
      <button type="button" onClick={OnNextService} disabled={currentService === servicesOffered.length - 1}>
        <FontAwesomeIcon icon={faArrowRight} />
      </button>

    </StyledMobileServices>
  );
}

export default MobileServices;
