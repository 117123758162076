import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import NoMatch from "../pages/NoMatch";
import Legals from "../pages/Legals";

function Layout({ servicesRef, referencesRef }) {
  return (
    <Routes>
      <Route path="/" element={<Home servicesRef={servicesRef} referencesRef={referencesRef} />} />
      <Route path="/mentions-legales" element={<Legals />} />
      {/* <Route path='/' element={}/>
      <Route path='/' element={}/> */}
      <Route path="*" element={<NoMatch />} />

    </Routes>
  );
}

export default Layout;
