import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { styled } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import deviceSize from "../responsive";
import ContactButton from "../ContactButton";

const StyledNavbar = styled.div`
  width: 100%;
  padding: 0.8rem;
  display: flex;
  position: fixed;
  top: 0;
  background-color: rgba(11, 23, 39,${(props) => (props.$navbarTransparency !== null ? props.$navbarTransparency : 1)});
  z-index: 1;
`;

const LinkContainer = styled.div`
  color: white;
  margin: auto 1rem auto auto;

  a {
    all: unset;
    margin: auto 1rem;
    cursor: pointer;
    width: 30%;
    &:hover{
      color: rgba(30, 166, 76, 1);
      border-bottom: 1px solid rgba(30, 166, 76, 1);

    }
  }

  ${({ $isMobile }) => $isMobile && `
      font-size: 12px;
      margin-right: 0;
  `}

  ${({ $navbarTransparency }) => !$navbarTransparency && `
      padding: 1rem;
  `};



`;

const NavLink = styled.a`
  all : unset;
  margin: auto 1rem;
  cursor: pointer;
`;

const Logo = styled.div`
  position: relative;
  padding-bottom: 0.2rem;

  img {
    width: auto;
    height: 6rem;
    position: absolute;
    top: -1.2rem;
    left: -1rem;

    ${({ $navbarTransparency }) => $navbarTransparency === 0 && `
      top: -1.5rem;
      left: -0.9rem;
      height: 9rem;
    `}

    ${({ $isMobile, $navbarTransparency }) => $isMobile && $navbarTransparency === 0 && `
      top: -1.1rem;
      left: -1.6rem;
      height: 7rem;
    `}

    ${({ $isMobile, $navbarTransparency }) => $isMobile && $navbarTransparency > 0 && `
          top: -1rem;
          left: -0.9rem;
          height: 5rem;
    `}
    transition: height 0.2s linear;
    
  }
`;

const StyledContactButton = styled(ContactButton)`
  /* visibility: ${(props) => (props.$navbarTransparency && props.$navbarTransparency > 0 ? "visible" : "hidden")}; */
  display: ${(props) => (props.$navbarTransparency && props.$navbarTransparency > 0 ? "visible" : "none")};

  margin-left: 0;

  ${({ $isMobile }) => $isMobile && `
    font-size: 11px;
  `}
`;

function Navbar({ servicesRef, referencesRef }, ref) {
  const location = useLocation();

  const [navbarTransparency, setNavbarTransparency] = useState(location.pathname === "/" ? 0 : 1);
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  const handleNavbarTransparency = () => {
    const alpha = (window.scrollY / window.innerHeight) * 2;
    if (alpha >= 1 || location.pathname !== "/") {
      setNavbarTransparency(1);
    } else {
      setNavbarTransparency(alpha);
    }
  };

  const handleServicesClick = useCallback(() => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleReferencesClick = useCallback(() => {
    referencesRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const displayLinks = useCallback(() => {
    if (location.pathname === "/") {
      return (
        <>
          <NavLink onClick={handleServicesClick}>Services</NavLink>
          <NavLink onClick={handleReferencesClick}>Références</NavLink>
        </>
      );
    }

    return <Link to="/">Accueil</Link>;
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleNavbarTransparency);
    return () => window.removeEventListener("scroll", handleNavbarTransparency);
  }, [location]);

  return (
    <StyledNavbar $navbarTransparency={navbarTransparency} ref={ref}>
      <Logo $isMobile={isMobile} $navbarTransparency={navbarTransparency}><img src="/logo.png" alt="logo" /></Logo>

      <LinkContainer $isMobile={isMobile} $navbarTransparency={navbarTransparency}>
        {displayLinks()}
      </LinkContainer>

      <StyledContactButton $navbarTransparency={navbarTransparency} $isMobile={isMobile} className="buttonRight" buttonText="J'ai un projet" />

    </StyledNavbar>
  );
}

export default React.forwardRef(Navbar);
