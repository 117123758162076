import { keyframes } from "styled-components";

export const slideBottomIn = keyframes`
  0% {
    opacity: 0.2 ;
    transform: translateY(500px) scale(0.1);
    
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fallDown = keyframes`
  0% {
    opacity: 0 ;
    transform: translateY(-400px) scale(0.1);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const blink = keyframes`
  0% { opacity: 0.1 ; transform: scale(0.6) };
  100% {opacity: 1; transform: scale(1)}
`;

export const growVertically = keyframes`
from {
  transform: scale(1,0.01);
}

to {
  transform: scale(1);
}
`;

export const growHorizontally = keyframes`
  from {
    transform: scale(0.02,1);
  }

  to {
    transform: scale(1, 1);
  }
`;

export const slideBottomUp = keyframes`
  0% {
    opacity: 0.2 ;
    transform: translateY(500px);
    
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
