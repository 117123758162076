import React, {
  useEffect, useMemo, useRef, useState,
} from "react";
import "./App.css";
import Layout from "./components/layout/Layout";
import Navbar from "./components/template/Navbar";
import Footer from "./components/template/Footer";
import AppContext from "./components/contexts/appContext";
import "@fontsource/poppins";

function App() {
  const navbarRef = useRef(null);
  const footerRef = useRef(null);

  const servicesRef = useRef(null);
  const referencesRef = useRef(null);

  const [navbarHeight, setNavbarHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const value = useMemo(() => ({ navbarHeight, footerHeight }), [navbarHeight, footerHeight]);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight);
    }
  }, [navbarRef, footerRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <AppContext.Provider value={value}>
      <div className="App">
        <Navbar ref={navbarRef} servicesRef={servicesRef} referencesRef={referencesRef} />
        <Layout servicesRef={servicesRef} referencesRef={referencesRef} />
        <Footer ref={footerRef} />
      </div>
    </AppContext.Provider>

  );
}

export default App;
